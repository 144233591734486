



















































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { StudentController } from '@/services/request.service'
import { getSchoolInfo } from '@/utils/utils'

@Component
export default class Parent extends Vue {
  private parentList: Array<any> = []

  private get locale(): string {
    return this.$store.state.locale
  }

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private created(): void {
    this.getData()
  }

  private getData(): void {
    const studentId = parseInt(this.$route.params.studentId, 10)
    StudentController.getParents(studentId)
      .then(res => {
        this.parentList = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }
}

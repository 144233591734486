








































































































import moment from 'moment'
import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import { ExamController, DropDownController } from '@/services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'

@Component({
  components: {
    FlexTooltip,
  },
})
export default class StudentExamPoints extends Vue {
  private currentTeacherType: Array<any> = []
  private examStatus: Number = 0
  private selectedTeacher: any = {}
  private examId: any = ''
  private data: any = []
  private schoolYearId: any = null
  private schoolYearList: Array<any> = []
  private exams: any = []

  private get studentId(): number {
    return Number(this.$route.params.studentId)
  }

  @Watch('studentId', { immediate: true })
  private onStudentChange(val): void {
    if (val) {
      this.getSchoolYearList()
    }
  }

  private created(): void {
    this.$nextTick(() => {
      this.getSchoolYearList()
    })
  }

  private handleClick(e): void {
    this.selectedTeacher = this.data.teacherComments.filter(item => item.description === e.key)[0]
  }

  private getExamDropdown(schoolYearId): void {
    ExamController.getExamDropdownByStudent(schoolYearId, this.studentId)
      .then(res => {
        this.exams = res.data
        if (res.data.length) {
          let defaultExamTime = res.data[0].extraValue.start
          this.examId = res.data[0].key
          this.examStatus = res.data[0].extraValue.status
          this.exams.forEach(exam => {
            if (exam.extraValue.start > defaultExamTime) {
              defaultExamTime = exam.extraValue.start
              this.examId = exam.key
              this.examStatus = exam.extraValue.status
            }
          })
          this.getData()
        } else {
          this.examId = undefined
          this.data = []
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getSchoolYearList(): void {
    DropDownController.getSchoolYearList().then(res => {
      this.schoolYearList = res.data
      this.schoolYearId = this.schoolYearList.filter(
        item => item.value.indexOf(moment().format('YYYY')) !== -1
      )[0].key
      this.getExamDropdown(this.schoolYearId)
    })
  }

  private getData(): void {
    ExamController.getTranscript(this.examId, this.studentId)
      .then(res => {
        this.data = res.data
        this.selectedTeacher = JSON.parse(JSON.stringify(this.data.teacherComments[0]))
        this.currentTeacherType = [this.selectedTeacher.description]
        this.data.transcriptItems = res.data.transcriptItems.map(item => {
          const stat = JSON.parse(JSON.stringify(item.gradings))
          if (JSON.stringify(stat) !== '{}') {
            for (let key in stat) {
              let target = this.data.items.filter(item => item.transcriptItemId === Number(key))[0]
              if (target.type === 'grading') {
                stat[key] = target.gradings.filter(item => item.level === stat[key])[0].description
              }
            }
          }
          return {
            ...item,
            ...stat,
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get columns(): Array<any> {
    let dynamicColumn = [] as any
    if (this.data.items) {
      dynamicColumn = this.data.items.map(item => {
        let tmpDecription =
          item.description > 20 ? item.description.slice(0, 20) + '...' : item.description
        return {
          key: item.transcriptItemId,
          title: tmpDecription,
          filterDropdown: item.type === 'centesimal', // 自定义的列筛选功能，我们占位为信息提示Icon的位置
          filterIcon: () => {
            return this.$createElement(
              'a-tooltip',
              {
                props: {
                  title: `(${item.from}-${item.to}${
                    item.integral ? this.$t('common.integral') : ''
                  })`,
                },
              },
              [
                this.$createElement('a-icon', {
                  props: {
                    type: 'question-circle',
                  },
                }),
              ]
            )
          },
          scopedSlots: { customRender: 'dynamic' + item.transcriptItemId },
        }
      })
    }
    if (this.data.comment) {
      const comment = {
        dataIndex: 'comment',
        key: 'comment',
        title: this.$t('exam.comment'),
        scopedSlots: { customRender: 'comment' },
        width: 150,
        ellipsis: true,
      }
      dynamicColumn.push(comment)
    }
    return [
      {
        dataIndex: 'subject',
        key: 'subject',
        title: this.$t('exam.subject'),
        fixed: 'left',
        width: 200,
        ellipsis: true,
      },
      {
        dataIndex: 'course',
        key: 'course',
        fixed: 'left',
        width: 200,
        title: this.$t('exam.courseClass'),
        ellipsis: true,
      },
      {
        dataIndex: 'teachers',
        key: 'teachers',
        title: this.$t('exam.teachers'),
        width: 200,
        ellipsis: true,
        fixed: 'left',
        scopedSlots: { customRender: 'teachers' },
      },
      ...dynamicColumn,
    ]
  }
}



























































import { Component, Vue, Emit } from 'vue-property-decorator'
import { StudentController, DropDownController } from '@/services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'

@Component({
  components: {
    FlexTooltip,
  },
})
export default class Subject extends Vue {
  private data: any = []
  private loading: boolean = true

  private filter: any = {
    schoolYearId: undefined,
  }
  private schoolYearList: any = []

  private get locale(): any {
    return this.$store.state.locale
  }

  private get columns(): Array<any> {
    return [
      {
        dataIndex: 'curriculum',
        key: 'curriculum',
        align: 'left',
        title: this.$t('myClass.student.curriculum'),
        ellipsis: true,
        scopedSlots: { customRender: 'common' },
      },
      {
        dataIndex: 'course',
        key: 'courseClass',
        align: 'left',
        title: this.$t('myClass.student.courseClass'),
        ellipsis: true,
        scopedSlots: { customRender: 'common' },
      },
      {
        dataIndex: 'subject',
        key: 'subject',
        align: 'left',
        title: this.$t('myClass.student.subject'),
        ellipsis: true,
        scopedSlots: { customRender: 'common' },
      },
      {
        dataIndex: 'sectionName',
        key: 'sectionName',
        align: 'left',
        title: this.$t('myClass.student.section'),
        width: 100,
      },
      {
        dataIndex: 'displayNames',
        key: 'teacher',
        align: 'left',
        title: this.$t('myClass.student.teacher'),
        ellipsis: true,
        scopedSlots: { customRender: 'common' },
      },
    ]
  }

  private created(): void {
    this.getSchoolYearList()
  }

  private getSchoolYearList(): void {
    DropDownController.getSchoolYearRuleList().then(res => {
      this.schoolYearList = res.data
      this.filter.schoolYearId = this.schoolYearList[0].key
      this.getData()
    })
  }

  private getData(): void {
    const studentId = parseInt(this.$route.params.studentId, 10)
    // StudentController.getSubjects(studentId)
    StudentController.getCourseInfo(this.filter.schoolYearId, studentId)
      .then(res => {
        this.data = res.data
        this.loading = false
      })
      .catch(err => {
        console.error(err)
      })
  }
}







































































import { Component, Vue, Emit } from 'vue-property-decorator'
import { StudentController, DropDownController } from '@/services/request.service'
import FlexTooltip from '@/components/FlexTooltip.vue'
import moment from 'moment'

@Component({
  components: {
    FlexTooltip,
  },
})
export default class TransferInfo extends Vue {
  private data: any = []
  private loading: boolean = true
  private studentInfo: any = {}
  private moment = moment

  private filter: any = {
    schoolYearId: undefined,
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get columns(): Array<any> {
    return [
      {
        dataIndex: 'courseSchedule',
        key: 'courseSchedule',
        align: 'left',
        title: this.$t('myClass.student.curriculum'),
        ellipsis: true,
        scopedSlots: { customRender: 'courseSchedule' },
      },
      {
        dataIndex: 'course',
        key: 'courseClass',
        align: 'left',
        title: this.$t('myClass.student.courseClass'),
        ellipsis: true,
        scopedSlots: { customRender: 'common' },
      },
      {
        dataIndex: 'subject',
        key: 'subject',
        align: 'left',
        title: this.$t('myClass.student.subject'),
        ellipsis: true,
        scopedSlots: { customRender: 'common' },
      },
      {
        dataIndex: 'grade',
        key: 'grade',
        align: 'left',
        title: this.$t('myClass.student.section'),
        width: 100,
      },
      {
        dataIndex: 'teachers',
        key: 'teacher',
        align: 'left',
        title: this.$t('myClass.student.teacher'),
        ellipsis: true,
        scopedSlots: { customRender: 'common' },
      },
    ]
  }

  private get studentId(): number {
    return parseInt(this.$route.params.studentId, 10)
  }

  private created(): void {
    this.getData()
  }

  private getData(): void {
    StudentController.getStudentTransInfo(this.studentId)
      .then(res => {
        this.studentInfo = res.data
        this.data = res.data.courseInfos
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }
}

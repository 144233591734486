























































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { DropDownController, StudentController } from '@/services/request.service'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import moment from 'moment'
import TableTotal from '@/components/TableTotal.vue'
import FlexTooltip from '@/components/FlexTooltip.vue'
import filterGroup from '@/components/filterGroup'
import {
  toPage,
  guid,
  clearEmptyArray,
  judgeVnode,
  handleBatchDownload,
  getToken,
  getSchoolInfo,
} from '@/utils/utils'
import { exportXlsx, writeFile } from '@/utils/xlsx'
import { createPagination } from '@/constant/constant'

@Component({
  components: {
    TableTotal,
    FlexTooltip,
    filterGroup,
  },
})
export default class MyStudent extends Vue {
  private dataEmptyFlag: boolean = false
  private exportLoading: boolean = false
  private sections: Array<any> = []
  private loading: boolean = false
  private houses: Array<any> = []
  private sectionIds: any = []

  private filter: any = {
    sectionIds: [],
    houseGroupId: 0,
    name: null,
    accommodation: 0,
    gender: 'all',
    homeroomId: [],
    dormitoryId: [],
    selfStudyRoomId: undefined,
    studentId: undefined,

    status: 'all',
    lastDate: [],
    transferDate: [],
  }
  private pagination: any = createPagination({})
  private data: Array<any> = []
  // private selectedRowKeys: Array<any> = []
  private downloading = false
  private moment = moment
  private homerooms: any = []
  private dormitoryList: Array<any> = []
  private studyRoomList: any = []
  private schoolYearList: Array<any> = []
  private copyLoading: boolean = false
  private visible: boolean = false
  private selectWeek: any = moment()
  private copyParLoading: boolean = false

  // 全选
  private allStudentsIds: any = []
  private selectedRows: Array<any> = []

  private get menuList(): Array<any> {
    return [
      {
        key: 'true',
        label: this.$t('myClass.normal'),
        auth: '2118',
      },
      {
        key: 'false',
        label: this.$t('myClass.transfer'),
        auth: '2119',
      },
    ].filter(item => this.operationAuths.includes(item.auth))
  }

  private get type(): any {
    return this.$route.params.status
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.name === 'myStudent') {
      if (!to.params.status) {
        if (this.operationAuths.includes('2118')) {
          this.$router.replace({ name: 'myStudent', params: { status: 'true' } }).catch(err => {})
        } else if (this.operationAuths.includes('2119')) {
          this.$router.replace({ name: 'myStudent', params: { status: 'false' } }).catch(err => {})
        }
      } else if (['true', 'false'].includes(to.params.status)) {
        this.reset()
      }
    }
  }

  private changeType({ item, key, keyPath }): void {
    this.$router.push({ name: 'myStudent', params: { status: key } }).catch(err => {})
    // this.reset()
  }

  private get selectedRowKeys(): any {
    return this.selectedRows.map(item => item.studentId)
    // return this.selectedRows
  }
  private get excelColumns(): Array<any> {
    return [
      {
        dataIndex: 'lastName',
        title: this.$t('common.surname'),
        width: 10,
      },
      {
        dataIndex: 'enName',
        title: this.$t('common.enName'),
        width: 10,
      },
      {
        dataIndex: 'firstName',
        title: this.$t('common.givenName'),
        width: 10,
      },
      {
        dataIndex: 'zhName',
        title: this.$t('common.cnName'),
        width: 10,
      },
      {
        dataIndex: 'studentNum',
        title: this.$t('common.studentId'),
        width: 30,
      },
      {
        dataIndex: 'birthday',
        title: this.$t('common.birthday'),
        width: 30,
      },
      {
        dataIndex: 'gender',
        title: this.$t('myClass.student.gender'),
        width: 15,
      },
      {
        dataIndex: 'section',
        title: this.$t('common.grade'),
        width: 15,
      },
      {
        dataIndex: 'className',
        title: this.$t('common.homeroom'),
        width: 20,
      },
      {
        dataIndex: 'house',
        title: this.$t('common.advisory'),
        width: 20,
      },
      {
        dataIndex: 'dormitory',
        title: this.$t('myClass.student.accommodation'),
        width: 20,
      },
      {
        dataIndex: 'dormitoryName',
        title: this.$t('dormitory.room'),
        width: 25,
      },
      {
        dataIndex: 'selfStudyRoomName',
        width: 30,
        title: this.$t('dormitory.studyRoom'),
      },
      {
        dataIndex: 'studentEmail',
        width: 50,
        title: this.$t('myClass.student.studentEmail'),
      },
      {
        dataIndex: 'parentsEmail',
        width: 60,
        title: this.$t('myClass.student.parentEmail'),
      },
    ]
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'myStudent'
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get columns(): Array<any> {
    const isTransfer = this.type === 'false'
    return [
      {
        key: 'name',
        title: this.$t('myClass.name'),
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
        width: 300 * this.ratio,
        fixed: 'left',
      },
      {
        dataIndex: 'studentNum',
        width: 120 * this.ratio,
        title: this.$t('myClass.studentId'),
      },
      {
        dataIndex: 'gender',
        width: 100 * this.ratio,
        title: this.$t('myClass.student.gender'),
        scopedSlots: { customRender: 'gender' },
      },
      {
        dataIndex: 'section',
        width: 100 * this.ratio,
        title: this.$t('myClass.section'),
      },

      !isTransfer && [
        {
          dataIndex: 'className',
          width: 150 * this.ratio,
          title: this.$t('myClass.mainClass'),
        },
        {
          dataIndex: 'house',
          width: 200 * this.ratio,
          title: this.$t('myClass.house'),
        },
        {
          dataIndex: 'dormitory',
          width: 120 * this.ratio,
          title: this.$t('myClass.student.accommodation'),
          scopedSlots: { customRender: 'dormitory' },
        },
        {
          dataIndex: 'dormitoryName',
          width: 120 * this.ratio,
          title: this.$t('dormitory.room'),
        },
        {
          dataIndex: 'selfStudyRoomName',
          width: 120 * this.ratio,
          title: this.$t('dormitory.studyRoom'),
        },
        {
          dataIndex: 'studentEmail',
          width: 300 * this.ratio,
          title: this.$t('myClass.student.studentEmail'),
        },
        {
          dataIndex: 'parentEmail',
          width: 300 * this.ratio,
          title: this.$t('myClass.student.parentEmail'),
        },
      ],
      isTransfer && [
        {
          dataIndex: 'status',
          title: this.$t('common.status'),
          width: 120 * this.ratio,
          scopedSlots: { customRender: 'status' },
        },
        {
          dataIndex: 'lastClassDate',
          title: this.$t('myClass.lastDate'),
          width: 200 * this.ratio,
          scopedSlots: { customRender: 'formatDate' },
        },
        {
          dataIndex: 'outDate',
          title: this.$t('myClass.transferDate'),
          width: 200 * this.ratio,
          scopedSlots: { customRender: 'formatDate' },
        },
      ],
    ]
      .filter(item => item)
      .flat()
  }

  private get formatSections(): any {
    return this.sections.map(item => {
      return {
        children: item.subOptions.map(child => ({
          key: child.key,
          title: this.locale === 'zh' ? child.value : child.enValue || child.value,
          value: child.key,
        })),
        title: `${item.value}-${item.enValue}`,
        key: `${item.enValue}_${item.extraValue}_${item.key}`,
        value: `${item.enValue}_${item.extraValue}_${item.key}`,
      }
    })
  }

  private get ratio(): any {
    return this.$store.state.ratio || 1
  }

  // @Watch('$route', { immediate: true })
  // private onRouteChange(to, from): void {
  //   this.refresh()
  // }

  private calcDate(status, date): any {
    const formatDate = moment(date).format('YYYY-MM-DD')
    return this.$t(`myClass.${status === '1017' ? '1017' : '1018'}`, { date: formatDate })
  }

  private created(): void {
    this.getDropDownInfo()
    // this.getData()
  }

  // 导出弹窗相关

  private async exportStudents(): Promise<void> {
    if (!this.selectedRowKeys.length) {
      this.visible = false
      this.$message.error(this.$tc('common.batchSelectError'))
      return
    }
    this.exportLoading = true
    const {
      sectionIds,
      houseGroupId,
      name,
      accommodation,
      gender,
      studentId,
      homeroomId,
      dormitoryId,
      selfStudyRoomId,
      lastDate,
      transferDate,
    } = this.filter
    const params = {
      pageCurrent: 1,
      pageSize: 0,
      sectionId: sectionIds.length ? sectionIds : undefined,
      houseId: houseGroupId || undefined,
      studentNum: studentId?.trim() || undefined,
      name: name ? name.trim() : undefined,
      dormitory: accommodation === 0 ? undefined : accommodation === 1,
      gender: gender === 'all' ? undefined : gender,
      classId: homeroomId?.[1],
      dormitoryId: dormitoryId?.[1],
      selfStudyRoomId: selfStudyRoomId,
      inSchool: this.type === 'true',
      lastClassDateStart: lastDate.length ? lastDate[0].valueOf() : undefined,
      lastClassDateEnd: lastDate.length ? lastDate[1].valueOf() : undefined,
      outDateStart: transferDate.length ? transferDate[0].valueOf() : undefined,
      outDateEnd: transferDate.length ? transferDate[1].valueOf() : undefined,
    }
    StudentController.getMyStudents(params)
      .then(async res => {
        const students = res.data.items
          .filter(item => this.selectedRowKeys.includes(item.studentId))
          .map(item => ({
            ...item,
            dormitory: this.$t(`common.${!!item.dormitory}`),
            gender: item.gender ? this.$t(`common.gender.${item.gender}`) : '',
            parentsEmail: item.parentsEmail ? item.parentsEmail.join('; ') : '',

            birthday: item.birthday ? moment(item.birthday).format('L') : '',
          }))

        const dataIndexList = [
          'lastName',
          'enName',
          'firstName',
          'zhName',
          'studentNum',
          'birthday',
          'gender',
          'section',
          'className',
          'house',
          'dormitory',
          'dormitoryName',
          'selfStudyRoomName',
          'studentEmail',
          'parentsEmail',
        ]
        const title = [
          this.$t('common.surname'),
          this.$t('common.enName'),
          this.$t('common.givenName'),
          this.$t('common.cnName'),
          this.$t('common.studentId'),
          this.$t('common.birthday'),
          this.$t('myClass.student.gender'),
          this.$t('common.grade'),
          this.$t('common.homeroom'),
          this.$t('common.advisory'),
          this.$t('myClass.student.accommodation'),
          this.$t('dormitory.room'),
          this.$t('dormitory.studyRoom'),
          this.$t('myClass.student.studentEmail'),
          this.$t('myClass.student.parentEmail'),
        ]
        let mainStr: any = []
        mainStr.push(title.join(',') + '\n')
        for (let i = 0; i < students.length; i++) {
          const temp: any = []
          for (let j = 0; j < dataIndexList.length; j++) {
            let strItem = students[i][dataIndexList[j]]
            strItem = `"${strItem}"`
            temp.push(strItem + '')
          }
          mainStr.push(temp.join(',') + '\n')
        }

        const result = mainStr.join('')
        const url = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(result)
        const link = document.createElement('a') //通过创建a标签实现
        link.href = url
        link.download = `Student Infomation.csv`
        document.body.appendChild(link)
        link.click()

        this.exportLoading = false
        document.body.removeChild(link)
      })
      .catch(err => {
        console.log(err)
        this.exportLoading = false
      })
  }

  private filterData(inputValue, path) {
    return path.some(option => option.value.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
  }

  private reset(): void {
    this.filter = {
      sectionIds: [],
      houseGroupId: 0,
      name: null,
      accommodation: 0,
      gender: 'all',
      homeroomId: [],
      dormitoryId: [],
      selfStudyRoomId: undefined,
      studentId: undefined,

      status: 'all',
      lastDate: [],
      transferDate: [],
    }
    this.sectionIds = []
    this.selectedRows = []
    this.$set(this.pagination, 'current', 1)
    this.refresh()
  }

  private getDropDownInfo(): void {
    Promise.all([
      DropDownController.getSectionCascade(),
      DropDownController.getHouseGroupListAll(),
      DropDownController.getClassListAll(),
      DropDownController.getFloorAndDormitoryListAll(),
      DropDownController.getSsRoomListAll(),
      DropDownController.getSchoolYearRuleList(),
    ])
      .then(res => {
        this.sections = res[0].data
        this.houses = res[1].data
        this.homerooms = clearEmptyArray(res[2].data)
        this.dormitoryList = clearEmptyArray(res[3].data)
        this.studyRoomList = res[4].data
        this.schoolYearList = res[5].data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getData(
    page = { current: 1, pageSize: this.pagination.defaultPageSize },
    isPage = false
  ): void {
    this.loading = true
    if (!isPage) {
      this.getStudentIdList()
    }
    const {
      sectionIds,
      houseGroupId,
      name,
      accommodation,
      gender,
      studentId,
      homeroomId,
      dormitoryId,
      selfStudyRoomId,
      lastDate,
      transferDate,
      status,
    } = this.filter
    const params = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      sectionId: sectionIds.length ? sectionIds : undefined,
      houseId: houseGroupId || undefined,
      studentNum: studentId?.trim() || undefined,
      name: name ? name.trim() : undefined,
      dormitory: accommodation === 0 ? undefined : accommodation === 1,
      gender: gender === 'all' ? undefined : gender,
      classId: homeroomId?.[1],
      dormitoryId: dormitoryId?.[1],
      selfStudyRoomId: selfStudyRoomId,
      inSchool: this.type === 'true',
      lastClassDateStart: lastDate.length ? lastDate[0].valueOf() : undefined,
      lastClassDateEnd: lastDate.length ? lastDate[1].valueOf() : undefined,
      outDateStart: transferDate.length ? transferDate[0].valueOf() : undefined,
      outDateEnd: transferDate.length ? transferDate[1].valueOf() : undefined,
      status: status === 'all' ? undefined : [status],
    }
    StudentController.getMyStudents(params)
      .then(res => {
        this.data = res.data.items
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
        if (!this.data.length) {
          this.dataEmptyFlag = true
        } else {
          this.dataEmptyFlag = false
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private fetchData = debounce(() => {
    this.getData()
  }, 500)

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination, true)
  }

  private clearSelection(): void {
    this.selectedRows = []
  }

  private selectAll(): void {
    this.clearSelection()
    this.selectedRows = cloneDeep(this.allStudentsIds)
  }

  private onSelect(record, selected, selectedRows, nativeEvent): void {
    const { studentId, studentEmail, parentsEmail } = record
    selected
      ? this.selectedRows.push({
          studentId,
          studentEmail,
          parentsEmail,
        })
      : this.selectedRows.splice(
          this.selectedRows.findIndex(item => item.studentId === record.studentId),
          1
        )
  }

  private onSelectAll(selected, selectedRows, changeRows): void {
    if (selected) {
      changeRows.forEach(item => {
        const { studentId, studentEmail, parentsEmail } = item
        this.selectedRows.push({ studentId, studentEmail, parentsEmail })
      })
    } else {
      changeRows.forEach(item => {
        this.selectedRows.splice(
          this.selectedRows.findIndex(row => row.studentId === item.studentId),
          1
        )
      })
    }
  }

  private viewDetail(studentId): void {
    this.$router.push({ name: 'studentDetail', params: { studentId } })
  }

  private sendMessage(): void {
    if (this.selectedRowKeys.length) {
      this.$store.commit('setMessageStudents', this.selectedRowKeys)
      this.$router.push({ name: 'sendMessage' })
    } else {
      this.$message.error(this.$tc('common.batchSelectError'))
    }
  }

  private addDiary(): void {
    // if (!this.selectedRowKeys.length) {
    //   this.$message.error(this.$tc('common.batchSelectError'))
    //   return
    // }
    this.$store.commit('setDiaryStudents', this.selectedRowKeys)
    this.$router.push({ name: 'diaryEditor', query: { type: 'add' } }).catch(err => {})
  }

  private onSectionChange(value, label, extra) {
    const isVNode = judgeVnode(extra.triggerNode)
    this.filter.sectionIds = (extra.allCheckedNodes || [])
      .map(item => {
        if (!isVNode) {
          if (item.children) {
            return item.children.map(item => item.node.key)
          } else {
            return item.node.key
          }
        } else {
          if (item.componentOptions.children.length) {
            return item.componentOptions.children.map(item => item.data.key)
          } else {
            return item.data.key
          }
        }
      })
      .flat()
    this.fetchData()
  }

  private async batchDownload(): Promise<any> {
    // console.log(this.selectWeek.isoWeekday(1).valueOf())
    const startOfWeek = this.selectWeek
      .day(1)
      .startOf('day')
      .valueOf()
    const endOfWeek = this.selectWeek
      .day(7)
      .endOf('day')
      .valueOf()
    if (this.selectedRowKeys.length === 0) {
      this.visible = false
      this.$message.error(this.$tc('common.batchSelectError'))
      return
    }
    this.downloading = true

    const res = await StudentController.getMyStudents({ pageCurrent: 1, pageSize: 0 })
    let key = (
      this.schoolYearList.filter(
        item => item.startTime <= startOfWeek && item.endTime >= endOfWeek
      )[0] || { key: 0 }
    ).key
    const startTime = moment()
      .startOf('isoWeek')
      .valueOf()
    const endTime = moment()
      .endOf('isoWeek')
      .valueOf()
    const name = '课表.zip'
    const domain = process.env.VUE_APP_DOMAIN
    const printUrl = process.env.VUE_APP_PDF_PRINT_URL
    const token = getToken()
    const schoolInfo = getSchoolInfo()
    let arr = [] as any
    this.selectedRowKeys.forEach((item, index) => {
      let targetUrl = `${domain}exportCurriculum/${token}/${
        schoolInfo.schoolId
      }/${startOfWeek}-${endOfWeek}/${'student'}/${item}/${key}`
      let url = `${printUrl}api/render/?url=${targetUrl}&pdf.landscape=true&pdf.printBackground=true&pdf.scale=0.45&pdf.margin.left=1mm&pdf.margin.right=1mm&pdf.margin.top=2mm&waitFor=1000`
      const student = res.data.items.find(student => student.studentId === item)
      arr.push({
        url,
        name: `${student?.className}_${student?.house}_${student?.name}.pdf`,
      })
      // window.open(targetUrl)
    })
    handleBatchDownload(arr, name, () => {
      this.downloading = false
      this.visible = false
    })
  }

  private getStudentIdList(): void {
    // const { sectionIds, houseGroupId, name, studentId } = this.filter
    const {
      sectionIds,
      houseGroupId,
      name,
      accommodation,
      gender,
      studentId,
      homeroomId,
      dormitoryId,
      selfStudyRoomId,
      lastDate,
      transferDate,
      status,
    } = this.filter
    const params = {
      pageCurrent: 1,
      pageSize: 0,
      sectionId: sectionIds.length ? sectionIds : undefined,
      houseId: houseGroupId || undefined,
      studentNum: studentId?.trim() || undefined,
      name: name ? name.trim() : undefined,
      dormitory: accommodation === 0 ? undefined : accommodation === 1,
      gender: gender === 'all' ? undefined : gender,
      classId: homeroomId?.[1],
      dormitoryId: dormitoryId?.[1],
      selfStudyRoomId: selfStudyRoomId,
      inSchool: this.type === 'true',
      lastClassDateStart: lastDate.length ? lastDate[0].valueOf() : undefined,
      lastClassDateEnd: lastDate.length ? lastDate[1].valueOf() : undefined,
      outDateStart: transferDate.length ? transferDate[0].valueOf() : undefined,
      outDateEnd: transferDate.length ? transferDate[1].valueOf() : undefined,
      status: status === 'all' ? undefined : [status],
    }
    // Promise.all([
    //   StudentController.getMyStudentId(params),
    //   StudentController.getMyStudentIds(sectionIds, houseGroupId || undefined, studentId || undefined, name?.trim() || undefined),
    // ])
    StudentController.getMyStudentId(params).then(res => {
      this.allStudentsIds = res.data
      // this.$store.commit('setAllStudentIds', res[1].data.map(item => item.studentId))
      this.$store.commit(
        'setAllStudentIds',
        res.data.map(item => {
          return { key: item.studentId, value: item.myStudent }
        })
      )
    })
  }

  private copyStudentEmail(): void {
    if (!this.selectedRowKeys.length) {
      this.$message.error(this.$tc('common.batchSelectError'))
    } else {
      console.log(this.selectedRows)
      this.copyLoading = true
      this.copyList(this.selectedRows.map(item => item.studentEmail))
      this.copyLoading = false
    }
  }

  // 复制邮箱
  private copyParentEmail(): void {
    if (!this.selectedRowKeys.length) {
      this.$message.error(this.$tc('common.batchSelectError'))
    } else {
      this.copyParLoading = true
      const parentList: any = []
      this.selectedRows.forEach(item => {
        parentList.push(item.parentsEmail.join(';'))
      })
      this.copyList(parentList)

      this.copyParLoading = false
    }
  }

  private copyList(list): void {
    const text = list.join(';')
    const input = document.createElement('input')
    document.body.appendChild(input)
    input.setAttribute('readonly', 'readonly')
    input.setAttribute('value', text)
    input.select()
    // ios兼容
    input.setSelectionRange(0, text.length)
    try {
      document.execCommand('copy')
    } catch (err) {
      console.log(err)
    }
    document.body.removeChild(input)
    this.$message.success(this.$tc('common.copySuccess'))
  }
}


















































































































































































import { Component, Vue, Emit, Watch } from 'vue-property-decorator'
import moment from 'moment'
import { StudentController } from '@/services/request.service'

@Component
export default class Student extends Vue {
  private studentForm: any
  private siblingsForm: any
  private data: any = {}
  private siblingsData: Array<any> = []
  private formLayout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }

  private moment = moment

  private get locale(): string {
    return this.$store.state.locale
  }

  private beforeCreate(): void {
    this.studentForm = this.$form.createForm(this)
    this.siblingsForm = this.$form.createForm(this)
  }

  private created(): void {
    this.getData()
  }

  private getData(): void {
    const studentId = parseInt(this.$route.params.studentId, 10)
    StudentController.detail(studentId)
      .then(res => {
        const { siblings, ...data } = res.data
        this.data = data
        this.siblingsData = siblings
      })
      .catch(err => {
        console.error(err)
      })
  }
}

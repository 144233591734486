























































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { DiaryController } from '@/services/request.service'
import InjuredMark from '@/components/InjuredMark.vue'
import moment from 'moment'
import { i18n } from '@/i18n/i18n'
import { toPage } from '@/utils/utils'
import { createPagination } from '@/constant/constant'
import FlexTooltip from '@/components/FlexTooltip.vue'

@Component({
  components: {
    InjuredMark,
    FlexTooltip,
  },
})
export default class Diary extends Vue {
  @Prop({ default: '1014' }) private readonly status!: string

  private visible: Array<boolean> = []
  private moment = moment
  private childTypes: Array<any> = []
  private isNurse: boolean = false
  private isAttendError: boolean = false
  private students: Array<any> = []
  private parents: Array<any> = []
  private teachers: Array<any> = []
  private modalInfo: any = {}
  private modalVisible: boolean = false
  private loading: boolean = true
  private dataSource: Array<any> = []
  private diaryTypes: Array<any> = []
  private studentId: any
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })
  private filter: any = {
    primaryTypeId: 0,
    diaryEntryTypeId: 0,
    createTime: [],
  }
  private get columns(): Array<any> {
    return [
      {
        dataIndex: this.locale === 'zh' ? 'primaryType' : 'primaryTypeEn',
        key: 'primaryType',
        title: this.$t('diary.diaryType'),
      },
      {
        dataIndex: this.locale === 'zh' ? 'diaryEntryType' : 'diaryEntryTypeEn',
        key: 'diaryEntryType',
        title: this.$t('diary.childType'),
      },
      {
        key: 'description',
        title: this.$t('diary.description'),
        scopedSlots: { customRender: 'description' },
      },
      {
        dataIndex: 'points',
        key: 'points',
        title: this.$t('diary.points'),
        scopedSlots: { customRender: 'points' },
      },
      // {
      //   key: 'notice',
      //   title: this.$t('diary.sendEmail'),
      //   scopedSlots: { customRender: 'notice' },
      // },
      {
        dataIndex: 'modifier',
        title: this.$t('common.operator'),
        scopedSlots: { customRender: 'operator' },
      },
      {
        key: 'recordTime',
        title: this.$t('diary.recordTime'),
        scopedSlots: { customRender: 'recordTime' },
      },
      this.status !== '1016' && {
        key: 'operations',
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
      },
    ].filter(item => item)
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private created(): void {
    this.$nextTick(() => {
      this.getPrimaryTypes()
      this.getData()
    })
  }

  private changeDiaryType(): void {
    this.filter.diaryEntryTypeId = 0
    const primaryTypeId = this.filter.primaryTypeId
    DiaryController.getDiaryEntryTypes(primaryTypeId, { headers: { 'X-Request-For': '2010' } })
      .then(res => {
        this.childTypes = res.data
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private closeModal(): void {
    this.modalVisible = false
  }

  private deleteRecord(index): void {
    // 删除
    DiaryController.deleteDiary(index, this.studentId, { headers: { 'X-Request-For': '2010' } })
      .then(res => {
        this.$message.success(this.$tc('common.deleteSuccess'))
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.$set(this.pagination, 'current', 1)
        this.refresh()
      })
  }

  private refresh(): void {
    this.getData(this.pagination)
  }

  private edit(diaryId): void {
    this.$router.push({ name: 'diaryEditor', query: { type: 'edit', diaryId } })
  }

  private viewDetail(diaryEntryId): void {
    DiaryController.getDiaryEntry(diaryEntryId, { headers: { 'X-Request-For': '2010' } })
      .then(res => {
        // 将日记簿详情贴进去
        this.modalInfo = res.data
        console.log('this.modalInfo:', this.modalInfo)
        this.visible = this.modalInfo.injureParts.map(item => false)
        console.log('该日记簿信息:', this.modalInfo)
        this.students = this.modalInfo.addressee.filter(item => item.type === 'student')
        this.parents = this.modalInfo.addressee.filter(item => item.type === 'parent')
        this.teachers = this.modalInfo.addressee.filter(item => item.type === 'teacher')

        if (this.modalInfo.shareWithParents) {
          this.modalInfo.parentsName = this.parents
            .map(item => item.name + this.$t('relation.' + item.relation))
            .join('、')
        }
        if (this.modalInfo.shareWithStudents) {
          this.modalInfo.studentsNameEnd = this.students.map(item => item.name).join('、')
        }

        this.modalInfo.teachersName = this.teachers.map(item => item.name).join('、')
        this.modalInfo.studentsName = this.modalInfo.students.map(item => item.name).join('、')
        if (res.data.primaryTypeId === 7) {
          this.isAttendError = false
        } else {
          this.isAttendError = true
        }
        if (res.data.primaryTypeId === 4) {
          this.isNurse = true
        } else {
          this.isNurse = false
        }
        this.modalVisible = true
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.studentId = this.$route.params.studentId
    const { primaryTypeId, diaryEntryTypeId, createTime } = this.filter
    const request = {
      studentId: this.studentId,
      primaryTypeId: primaryTypeId || undefined,
      diaryEntryTypeId: diaryEntryTypeId || undefined,
      start: createTime[0] ? moment(createTime[0]).valueOf() : undefined,
      end: createTime[1] ? moment(createTime[1]).valueOf() : undefined,
      pageCurrent: page.current,
      pageSize: page.pageSize,
    }

    DiaryController.getDiaryEntrySummary(
      request.pageCurrent,
      request.pageSize,
      request.studentId,
      request.primaryTypeId,
      request.diaryEntryTypeId,
      request.start,
      request.end,
      undefined,
      undefined,
      { headers: { 'X-Request-For': '2010' } }
    )
      .then(res => {
        this.dataSource = res.data.diaryEntryItemResponsePagedList.items
        this.pagination.total = res.data.diaryEntryItemResponsePagedList.totalItem
        this.pagination.current = page.current
        toPage(page, this.dataSource, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private getPrimaryTypes(): void {
    DiaryController.getPrimaryTypes(undefined, undefined, { headers: { 'X-Request-For': '2010' } })
      .then(res => {
        this.diaryTypes = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }
}

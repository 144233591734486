






























































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import {
  StudentController,
  DropDownController,
  TaskGradeController,
  GradeController,
  MonthlyGradeController,
} from '@/services/request.service'
import { getSchoolInfo, getToken } from '@/utils/utils'
import FlexTooltip from '@/components/FlexTooltip.vue'
import PreviewModal from '@/components/Report/PreviewModal.vue'
import EventBus from '@/utils/EventBus'
import saveAs from '@/utils/FileSaver'
import moment from 'moment'

const getReportList: any = {
  8: function(schoolYearId, studentId) {
    return MonthlyGradeController.getMonthlyReportsByStudent(schoolYearId, studentId)
  },
  default: function(schoolYearId, studentId) {
    return GradeController.getGradeReports(schoolYearId, studentId)
  },
}

const operations: any = {
  high: function(periodId, studentId) {
    return GradeController.getUpperSchoolReport(periodId, studentId)
  },
  middle: function(periodId, studentId) {
    return GradeController.getMidSchoolReport(periodId, studentId)
  },
  elementary: function(periodId, studentId) {
    return GradeController.getGradeReportDetail(studentId, periodId)
  },
  middleYear: function(periodId, studentId) {
    return GradeController.getMidSchoolAnnualReport(studentId, periodId)
  },
  highYear: function(periodId, studentId) {
    return GradeController.getUpperSchoolAnnualReport(studentId, periodId)
  },
  monthly: function(periodId, studentId) {
    return MonthlyGradeController.getMonthlyReport(periodId, studentId)
  },
}

@Component({
  components: {
    FlexTooltip,
    PreviewModal,
  },
})
export default class Report extends Vue {
  @Prop({ default: '' }) public studentName!: string
  @Prop({ default: '' }) public studentId!: string
  private filter: any = {
    schoolYearId: undefined,
  }
  private schoolYears: Array<any> = []
  private data: any = []
  private campusCode: any = {
    1231: 'elementary',
    1232: 'middle',
    1233: 'high',
  }
  private campusType: any = 'elementary'
  private previewing: any = false
  private reportData: any = []
  private previewVisible: any = false
  private reportType: any = 'term'
  private schoolYearType: any = '2021-2022'

  private get columns(): Array<any> {
    return [
      {
        key: 'name',
        title: this.$t('myClass.student.reportName'),
        scopedSlots: { customRender: 'name' },
      },
      {
        key: 'schoolYear',
        title: this.$t('common.schoolYear'),
        dataIndex: 'schoolYear',
      },
      // {
      //   key: "gradePeriod",
      //   title: this.$t("myClass.student.gradePeriod"),
      //   dataIndex: "gradePeriodName",
      // },
      {
        dataIndex: 'reportType',
        title: this.$t('myClass.student.reportType'),
        scopedSlots: { customRender: 'reportType' },
      },
      {
        key: 'status',
        title: this.$t('common.status'),
        scopedSlots: { customRender: 'status' },
      },
      {
        key: 'operations',
        title: this.$t('common.operations'),
        scopedSlots: { customRender: 'operations' },
      },
    ]
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private get schoolId(): number {
    return (getSchoolInfo() || {}).schoolId
  }

  private created(): void {
    this.getSchoolYears()
  }

  private getSchoolYears(): void {
    DropDownController.getSchoolYearRuleList()
      .then(res => {
        this.schoolYears = res.data
        this.$set(this.filter, 'schoolYearId', res.data[0].key)
        this.schoolYearType = res.data[0].value
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private changeYear(): void {
    this.schoolYearType = this.schoolYears.find(item => item.key === this.filter.schoolYearId).value
    this.getData()
  }

  private getData(): void {
    const studentId = parseInt(this.$route.params.studentId, 10)
    getReportList[this.schoolId === 8 ? '8' : 'default'](this.filter.schoolYearId, studentId)
      .then(res => {
        this.data = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private view(record): void {
    this.previewing = true
    this.campusType = this.campusCode[record.type] || ''
    this.reportType = ['monthly', 'longTerm', 'exam'].includes(record.reportType || record.type)
      ? 'monthly'
      : record.reportType === 'fullSchoolYear'
      ? 'yearly'
      : 'term'
    const type = ['monthly', 'longTerm', 'exam'].includes(record.reportType || record.type)
      ? 'monthly'
      : record.reportType === 'fullSchoolYear'
      ? this.campusCode[record.type] + 'Year'
      : this.campusCode[record.type]
    const studentId = parseInt(this.$route.params.studentId, 10)
    operations[type](record.gradePeriodId, studentId)
      .then(res => {
        this.reportData = res.data
        this.previewVisible = true
      })
      .catch(err => console.log(err))
      .finally(() => (this.previewing = false))
  }

  private download(record) {
    this.$set(record, 'downloading', true)
    const studentId = parseInt(this.$route.params.studentId, 10)
    const domain = process.env.VUE_APP_DOMAIN
    const printUrl = process.env.VUE_APP_PDF_PRINT_URL
    const type = ['monthly', 'longTerm', 'exam'].includes(record.reportType)
      ? 'monthly'
      : record.reportType === 'fullSchoolYear'
      ? 'yearly'
      : 'term'
    let fileName =
      this.schoolId === 8
        ? `${this.studentName}_${record.enName}_${record.name}.pdf`
        : `${this.studentName}_${record.gradeReportName}_${record.gradeReportEnName}.pdf`
    let token = getToken()
    let schoolInfo = getSchoolInfo()
    let targetUrl =
      this.schoolId === 8
        ? `${domain}exportMonthlyReport/${token}/${this.schoolId}/${studentId}/${record.gradePeriodId}`
        : `${domain}exportReport/${token}/${schoolInfo.schoolId}/${studentId}/${
            record.gradePeriodId
          }/${type}/${this.campusCode[record.type]}/${moment().valueOf()}/${record.schoolYear}`
    let url = `${printUrl}api/render/?url=${targetUrl}&pdf.printBackground=true&pdf.margin.left=0mm&pdf.margin.right=0mm&pdf.margin.top=${
      this.schoolId === 8 ? 28 : 4
    }mm&waitFor=1500`

    saveAs(url, fileName, record.gradePeriodId)
    EventBus.$once(`onload_${record.gradePeriodId}`, () => {
      this.$set(record, 'downloading', false)
    })
  }
}

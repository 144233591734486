


























import { Component, Vue, Prop } from 'vue-property-decorator'
import { CurriculumController, DropDownController } from '@/services/request.service'
import moment, { Moment } from 'moment'
import flattenDeep from 'lodash/flattenDeep'
import curriculum from '@/components/NewCurriculum.vue'
import debounce from 'lodash/debounce'
@Component({
  components: {
    curriculum,
  },
})
export default class Curriculum extends Vue {
  @Prop({ default: '1014' }) private readonly status!: string

  private startTime = moment().startOf('isoWeek')
  private endTime = moment().endOf('isoWeek')

  private yearKey: number = 0
  private yearList: Array<any> = []

  private substitute = 0
  private fullScreen: boolean = false
  private loading: boolean = false
  private resizeEvent: any = null
  private curriculumData: any = {
    classArranges: [],
    classPeriods: [],
    dayOfArranged: [],
    yearList: [],
  }

  private moment = moment

  private async created() {
    await this.getYearList()
    await this.getMyCurriculum()
  }
  private getYearList() {
    return DropDownController.getSchoolYearRuleList().then(res => {
      if (res.status == 200) {
        this.yearList = res.data
        this.yearKey = res.data[0].key
      }
    })
  }
  private getMyCurriculum() {
    console.log(
      'this.startTime__',
      this.startTime,
      this.startTime.format('YYYY-MM-DD'),
      'this.endTime__',
      this.endTime,
      this.endTime.format('YYYY-MM-DD')
    )
    const start = this.startTime.valueOf()
    const end = this.endTime.valueOf()
    console.log('start__', start, 'end__', end)
    this.loading = true
    const studentId = parseInt(this.$route.params.studentId, 10)
    this.curriculumData = {
      substitute: 0,
      total: 0,
      classArranges: [],
      classPeriods: [],
      dayOfArranged: [],
      yearList: [],
    }
    return CurriculumController.getClassArrangesByStudentAndWeek(
      this.yearKey,
      studentId,
      start,
      end
    )
      .then(res => {
        const { classArranges, classPeriods, dayOfArranged } = res.data
        let total = 0
        try {
          total = Object.keys(classArranges)
            .map(
              key =>
                classArranges[key] &&
                Object.keys(classArranges[key])
                  .map(c => classArranges[key][c])
                  .filter(item => item.type !== 'periodArranges').length
            )
            .filter(f => f || f === 0)
            .reduce((total, current) => total + current)
        } catch (err) {
          console.log(err)
        }
        this.curriculumData = {
          classArranges,
          classPeriods,
          dayOfArranged,
          total,
          yearList: this.yearList,
        }
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }

  private onTimeChange(params): void {
    if (!params && !params.length) return
    this.startTime = params[0]
    this.endTime = params[1]
    this.getMyCurriculum()
  }
  private onYearChange(params): void {
    this.yearKey = params
    let startTime = this.yearList.filter(item => {
      return params === item.key
    })[0].startTime
    this.startTime = moment(startTime).startOf('isoWeek')
    this.endTime = moment(startTime).endOf('isoWeek')
    this.getMyCurriculum()
  }

  private requestFullScreen() {
    const el = document.getElementById('studentCurriculum') as any
    this.fullScreen = true
    const requestMethod =
      el.requestFullScreen ||
      el.webkitRequestFullScreen ||
      el.mozRequestFullScreen ||
      el.msRequestFullScreen
    if (requestMethod) {
      requestMethod.call(el)
    } else if (typeof window.ActiveXObject !== 'undefined') {
      // eslint-disable-next-line
      let wscript = new ActiveXObject('WScript.Shell')
      if (wscript !== null) {
        wscript.SendKeys('{F11}')
      }
    }
  }

  private exitFullScreen(): void {
    const document = this.$document as any
    this.fullScreen = false
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
  }

  private mounted() {
    this.resizeEvent = debounce(this.onResize, 100)
    window.addEventListener('resize', this.resizeEvent)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.resizeEvent)
    })
  }

  private onResize(): void {
    // 利用屏幕分辨率和window对象的内高度来判断兼容IE
    let winFlag = window.innerHeight === window.screen.height
    // 利用window全屏标识来判断 -- IE无效
    let isFull = (window as any).fullScreen || (document as any).webkitIsFullScreen
    if (isFull === undefined) {
      this.fullScreen = winFlag
    } else {
      this.fullScreen = winFlag || isFull
    }
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grade"},[(_vm.showAll)?_c('div',{staticClass:"section-title"},[_c('div',{staticClass:"title-bar"}),_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.$t('myClass.student.gradeBook')))])]):_vm._e(),_c('div',{staticClass:"filter-container"},[_c('div',{staticClass:"filter-item"},[_c('span',{staticClass:"filter-text"},[_vm._v(_vm._s(_vm.$t('common.schoolYear')))]),_c('a-select',{staticClass:"filter-input",on:{"change":function($event){return _vm.getData()}},model:{value:(_vm.filter.schoolYearId),callback:function ($$v) {_vm.$set(_vm.filter, "schoolYearId", $$v)},expression:"filter.schoolYearId"}},_vm._l((_vm.schoolYears),function(item){return _c('a-select-option',{key:item.key},[_vm._v(_vm._s(_vm.locale === 'zh' ? item.value : item.enValue || item.value))])}),1)],1)]),(_vm.status !== '1016' && _vm.showAll)?_c('a-button',{staticStyle:{"float":"right"},attrs:{"loading":_vm.exportLoading,"disabled":!_vm.data.length},on:{"click":_vm.exportGradeBook}},[_c('a-icon',{attrs:{"type":"vertical-align-bottom"}}),_c('span',[_vm._v(_vm._s(_vm.$t('common.export')))])],1):_vm._e(),_c('div',{staticClass:"section-content"},[_c('a-table',{attrs:{"columns":_vm.columns,"dataSource":_vm.data,"pagination":false,"scroll":{
        x: 520 + (_vm.semesters.length + _vm.maxTaskNum) * 120,
        y: _vm.windowH - (_vm.showAll ? 190 : 150),
      },"rowKey":function (record, index) { return index; }},scopedSlots:_vm._u([{key:"subject",fn:function(text, record){return [_c('flex-tooltip',{attrs:{"value":text + (record.outFlag ? ("(" + (_vm.$t('myClass.student.outClass')) + ")") : '')}})]}},{key:"finalScore",fn:function(record){return [_c('div',{staticStyle:{"display":"flex","flex-direction":"column","justify-contents":"center"}},[_c('span',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(record.finalScore === -1 ? '--' : _vm.round(record.finalScore * 100, 1)))]),_c('span',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(record.gradeLevel))])])]}},_vm._l((_vm.semesters),function(semester){return {key:'semester-' + semester.gradePeriodId,fn:function(record){return [(record.gradePeriodScores)?_c('div',{key:semester.gradePeriodId,staticStyle:{"display":"flex","flex-direction":"column","justify-contents":"center"}},[(
              !record.gradePeriodScores[semester.gradePeriodId].manualScore ||
                record.gradePeriodScores[semester.gradePeriodId].manualScore === -1
            )?[_c('span',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(record.gradePeriodScores[semester.gradePeriodId].score === -1 ? '--' : _vm.round(record.gradePeriodScores[semester.gradePeriodId].score * 100, 1))+" ")]),_c('span',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(record.gradePeriodScores[semester.gradePeriodId].grade))])]:[_c('span',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.round(record.gradePeriodScores[semester.gradePeriodId].manualScore, 1)))]),_c('span',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(record.gradePeriodScores[semester.gradePeriodId].manualGrade))])]],2):_vm._e()]}}}),_vm._l((_vm.maxTaskNum),function(task,index){return {key:'task-' + index,fn:function(record){return [(record.taskScores.length)?_c('div',{key:index,staticStyle:{"width":"120px","display":"flex","flex-direction":"column"}},[(record.taskScores[index])?[_c('div',{staticClass:"task-title",style:({
                backgroundColor: record.taskScores[index].color,
              })},[_c('a-tooltip',{attrs:{"placement":"topLeft","title":record.taskScores[index].taskName}},[_c('span',[_vm._v(_vm._s(record.taskScores[index].taskName))])])],1),_c('div',{staticStyle:{"padding":"4px 0","text-align":"center"}},[_c('span',{staticStyle:{"min-height":"21px","display":"block"}},[_vm._v(_vm._s(!record.taskScores[index].score && record.taskScores[index].score !== 0 ? '' : record.taskScores[index].score === -1 ? '--' : record.taskScores[index].score + '/' + record.taskScores[index].topScore))])])]:_c('div',{key:index})],2):_vm._e()]}}})],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }





















































































































































































































































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import {
  AttendanceController,
  DormitoryAttendanceController,
  SsRoomAttendanceController,
  DropDownController,
} from '@/services/request.service'
import moment, { Moment } from 'moment'
import AttendanceDetail from './AttendanceDetail.vue'
import { statusList } from '@/constant/constant'
import { calcBg, calcSymbol } from '@/utils/utils'
import SvgIcon from '@/components/SvgIcon.vue'

@Component({
  components: {
    AttendanceDetail,
    SvgIcon,
  },
})
export default class Attendance extends Vue {
  @Prop() private readonly campusType!: any
  @Prop() private readonly attendanceType!: any

  private startTime = moment().startOf('isoWeek')
  private endTime = moment().endOf('isoWeek')
  private filter: any = {
    date: [],
    courseDate: [],
    schoolYearId: undefined,
  }
  private countData: any = []
  private data: Array<any> = []
  private periods: Array<any> = []
  private date = moment().startOf('month')
  private loading = false
  private calcBg = calcBg
  private calcSymbol = calcSymbol
  private courseAttenData: any = []

  private moment = moment

  // private schoolYearId: any = null
  private schoolYearList: any = []

  private get statusList(): any {
    return this.$store.state.attendanceStatusList.length
      ? this.$store.state.attendanceStatusList
      : statusList
  }

  private get isPre(): any {
    return this.campusType === '1230'
  }

  private get operationAuths(): any {
    return this.$store.state.operationAuths
  }

  private get columns(): any {
    return [
      {
        key: 'dimension',
        title: this.$t('common.subjectClass'),
        ellipsis: true,
        scopedSlots: { customRender: 'subjectClass' },
      },
      {
        key: 'late',
        title: this.$t('attendance.statusList.late'),
        scopedSlots: { customRender: 'commonBlock' },
      },
      {
        key: 'absent',
        title: this.$t('attendance.statusList.absent'),
        scopedSlots: { customRender: 'commonBlock' },
      },
      {
        key: 'personal',
        title: this.$t('attendance.statusList.personal'),
        scopedSlots: { customRender: 'commonBlock' },
      },
      {
        key: 'examAbsent',
        title: this.$t('attendance.statusList.examAbsent'),
        scopedSlots: { customRender: 'commonBlock' },
      },
      {
        key: 'illness',
        title: this.$t('attendance.statusList.illness'),
        scopedSlots: { customRender: 'commonBlock' },
      },
      {
        key: 'holiday',
        title: this.$t('attendance.statusList.holiday'),
        scopedSlots: { customRender: 'commonBlock' },
      },
      {
        key: 'exams',
        title: this.$t('attendance.statusList.exams'),
        scopedSlots: { customRender: 'commonBlock' },
      },
      {
        key: 'others',
        title: this.$t('attendance.statusList.others'),
        scopedSlots: { customRender: 'commonBlock' },
      },
    ]
  }

  private created(): void {
    this.getSchoolYearList()
  }

  private getSchoolYearList(): void {
    DropDownController.getSchoolYearRuleList(undefined, {
      headers: { 'X-Request-For': '2006' },
    }).then(res => {
      this.schoolYearList = res.data
      this.filter.schoolYearId = this.schoolYearList[0].key
      this.getAttendance()
    })
  }

  private handleChangeSchoolYear(schoolYearId): void {
    const currentYear = this.schoolYearList.find(item => item.key === schoolYearId)
    this.startTime = moment(currentYear.startTime).startOf('isoWeek')
    this.endTime = moment(currentYear.startTime).endOf('isoWeek')
    this.getAttendance()
  }

  private disabledDate(current): any {
    return (
      current &&
      (current < moment(this.currentSchoolYear.startTime).startOf('day') ||
        current > moment(this.currentSchoolYear.endTime).endOf('day'))
    )
  }

  private getAttenCount(): void {
    if (this.isPre) return
    const studentId = parseInt(this.$route.params.studentId, 10)
    this.countData = []
    AttendanceController.getStudentStatisticsDetail(
      this.filter.schoolYearId,
      this.filter.date[0].valueOf(),
      this.filter.date[1].valueOf(),
      studentId,
      { headers: { 'X-Request-For': '2009' } }
    ).then(res => {
      this.countData = statusList
        .filter(item => item !== 'intime')
        .map(item => {
          const {
            attendances,
            dormitoryAttendances,
            sessionAttendances,
            studyRoomAttendances,
          } = res.data
          const formData = {}
          Object.keys(attendances || {}).forEach(key => {
            const count = attendances[key]?.[item]
            this.$set(formData, key, count)
          })
          return {
            status: item,
            am: sessionAttendances.am?.[item],
            pm: sessionAttendances.pm?.[item],
            day: sessionAttendances.day?.[item],
            dAtten: {
              am: ['late', 'examAbsent'].includes(item) ? '--' : dormitoryAttendances.am?.[item],
              pm: ['late', 'examAbsent'].includes(item) ? '--' : dormitoryAttendances.pm?.[item],
              eve: ['late', 'examAbsent'].includes(item) ? '--' : dormitoryAttendances.eve?.[item],
            },
            sRAtten: {
              eve: ['late', 'examAbsent'].includes(item) ? '--' : studyRoomAttendances.eve?.[item],
            },
            ...formData,
          }
        })
      this.countData = this.countData.map(item => {
        const total = Object.keys(item)
          .map(key => {
            if (key === 'status') return undefined
            const ele = item[key]
            if (typeof ele === 'object') {
              return Object.keys(ele).map(eKey => ele[eKey])
            } else {
              return ele
            }
          })
          .flat()
          .filter(item => item && item !== '--')
          // 防止 reduce 报空数组的错
          .concat([0])
          .reduce((total, current) => total + current)

        return {
          ...item,
          total,
        }
      })
    })
  }

  private getCourseAttenCount(): void {
    if (this.isPre) return
    const studentId = parseInt(this.$route.params.studentId, 10)
    this.courseAttenData = []
    this.loading = true
    AttendanceController.getStudentCourseStatistics(
      this.filter.courseDate[0].valueOf(),
      this.filter.courseDate[1].valueOf(),
      studentId,
      { headers: { 'X-Request-For': '2009' } }
    )
      .then(res => {
        const { statistics, ...rest } = res.data
        this.courseAttenData = (statistics as any).concat([
          {
            ...rest,
            dimension: '总计',
            enDimension: 'Total',
            boldFlag: true,
          },
        ])
      })
      .catch(err => console.error(err))
      .finally(() => (this.loading = false))
  }

  private onDateChange(params): void {
    if (this.loading) return
    this.date =
      params.type === 'pre'
        ? moment(this.date).subtract(1, 'month')
        : moment(this.date).add(1, 'month')
    this.getAttendance()
  }

  private get currentSchoolYear(): any {
    // return this.$store.state.currentSchoolYear
    return this.schoolYearList.length
      ? this.schoolYearList.find(item => item.key === this.filter.schoolYearId)
      : false
  }

  @Watch('currentSchoolYear', { deep: true, immediate: true })
  private onYearChange(val): void {
    if (val && !this.isPre) {
      this.filter.date = [moment(val.startTime), moment(val.endTime)]
      this.filter.courseDate = [moment(val.startTime), moment(val.endTime)]
      this.getAttenCount()
      this.getCourseAttenCount()
    }
  }

  private get lastDisabled(): any {
    return (
      moment(this.endTime).subtract(1, 'week') <=
      moment(this.currentSchoolYear.startTime).startOf('day')
    )
  }

  private get nextDisabled(): any {
    return (
      moment(this.startTime).add(1, 'week') >= moment(this.currentSchoolYear.endTime).endOf('day')
    )
  }

  private get studentId(): any {
    return parseInt(this.$route.params.studentId, 10) || undefined
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private getAttendance(): void {
    // this.loading = true
    const studentId = this.studentId
    // this.data = []
    !this.isPre
      ? this.attendanceType !== 'full_day'
        ? AttendanceController.getStudentStatistics(
            this.filter.schoolYearId,
            studentId,
            this.startTime.valueOf(),
            this.endTime.valueOf(),
            { headers: { 'X-Request-For': '2006' } }
          )
            .then(res => {
              this.data = res.data
              this.periods = res.data[0].classPeriods
            })
            .catch(err => {
              console.error(err)
            })
        : // .finally(() => this.loading = false)
          AttendanceController.getEleStudentStatistics(
            this.filter.schoolYearId,
            this.startTime.valueOf(),
            this.endTime.valueOf(),
            studentId,
            { headers: { 'X-Request-For': '2006' } }
          ).then(res => {
            this.data = res.data
            this.periods = res.data[0].classPeriods
          })
      : AttendanceController.getKGStudentAttendance(studentId, this.date.valueOf(), {
          headers: { 'X-Request-For': '2006' },
        })
          .then(res => {
            this.data = res.data
          })
          .catch(err => {
            console.error(err)
          })
    // .finally(() => (this.loading = false))
  }

  private prevWeek(): void {
    const startTime = moment(this.startTime).subtract(1, 'week')
    const endTime = moment(this.endTime).subtract(1, 'week')
    if (endTime <= moment(this.currentSchoolYear.startTime).startOf('day')) return
    this.startTime = moment(startTime)
    this.endTime = moment(endTime)
    // console.log(this.startTime)
    this.getAttendance()
  }

  private nextWeek(): void {
    const startTime = moment(this.startTime).add(1, 'week')
    const endTime = moment(this.endTime).add(1, 'week')
    if (startTime >= moment(this.currentSchoolYear.endTime).endOf('day')) return
    this.startTime = moment(startTime)
    this.endTime = moment(endTime)
    this.getAttendance()
  }

  private setAttendance(row, key, type): void {
    if (type === 'day') {
      const condition = {
        studentId: this.studentId,
        date: row.date,
        status: key,
        comment: row.comment || '',
      }
      AttendanceController.setAttendanceKG(condition, { headers: { 'X-Request-For': '2006' } })
        .then(res => {
          this.$set(row, 'status', key)
          this.$message.success(this.$tc('tips.saveSuccess'))
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.getAttendance()
          this.getAttenCount()
        })
    } else {
      const condition = {
        date: row.date,
        type,
        studentId: this.studentId,
        status: key,
      }
      AttendanceController.updateDailyAttendance(condition, {
        headers: { 'X-Request-For': '2006' },
      })
        .then(res => {
          this.$set(row, type, key)
          this.$message.success(this.$tc('tips.saveSuccess'))
        })
        .catch(err => console.log(err))
        .finally(() => {
          this.getAttendance()
          this.getAttenCount()
        })
    }
  }

  private getDimension(record): any {
    return this.locale === 'zh' ? record.dimension : record.enDimension || record.dimension
  }

  private setPeriodAttendance(row, key, col): void {
    const period = row.attendances[col.description]
    const condition = {
      classArrangeId: period.classArrangeId,
      studentId: this.studentId,
      status: key,
    }
    AttendanceController.updateClassAttendance(condition, { headers: { 'X-Request-For': '2006' } })
      .then(res => {
        this.$set(row.attendances, col.description, {
          ...period,
          status: key,
          comment: row.attendances[col.description]?.comment || '',
        })
        this.$message.success(this.$tc('tips.saveSuccess'))
      })
      .catch(err => console.log(err))
      .finally(() => {
        this.getAttendance()
        this.getAttenCount()
      })
  }

  private updateDormitoryAtten(row, key, type) {
    const condition = { date: row.date, type, studentId: this.studentId, status: key }
    DormitoryAttendanceController.update(condition, { headers: { 'X-Request-For': '2006' } })
      .then(res => {
        row.dormitoryAttendances[type].status = key
        this.$message.success(this.$tc('tips.saveSuccess'))
      })
      .catch(err => console.log(err))
  }

  private updateStudyRoomAtten(row, key, type) {
    const condition = { date: row.date, type, studentId: this.studentId, status: key }
    SsRoomAttendanceController.update(condition, { headers: { 'X-Request-For': '2006' } })
      .then(res => {
        row.studyRoomAttendances[type].status = key
        this.$message.success(this.$tc('tips.saveSuccess'))
      })
      .catch(err => console.log(err))
  }
}

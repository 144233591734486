


























































































































import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator'
import {
  StudentController,
  GradeController,
  TaskGradeController,
  DropDownController,
} from '@/services/request.service'
import { DropdownButton } from 'ant-design-vue/types/dropdown/dropdown-button'
import { round } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { exportXlsx, writeFile } from '@/utils/xlsx'
import { Debounce } from '@/utils/utils'
import FlexTooltip from '@/components/FlexTooltip.vue'

@Component({
  components: {
    FlexTooltip,
  },
})
export default class Grading extends Vue {
  @Prop({ default: '1014' }) private readonly status!: string
  @Prop({ default: true }) private readonly showAll!: boolean

  private filter: any = {
    schoolYearId: undefined,
  }
  private schoolYears: Array<any> = []
  private data: Array<any> = []
  private semesters: Array<any> = []
  private maxTaskNum = 0
  private round = round
  private windowH = 0
  private exportLoading = false

  @Watch('studentId', { immediate: true })
  private onIdChange(val) {
    if (!val) return
    if (this.schoolYears.length) {
      this.getData()
    } else {
      this.getSchoolYears()
    }
  }

  private get studentId(): number {
    return parseInt(this.$route.params.studentId, 10)
  }

  private get columns(): Array<any> {
    let tasks = [] as any
    for (let i = 0; i < this.maxTaskNum; i++) {
      tasks.push({
        key: 'task-' + i,
        colSpan: i === 0 ? this.maxTaskNum : 0,
        align: 'left',
        width: 120,
        title: this.$t('router.assignment'),
        customCell: record => {
          return {
            style: {
              padding: '0',
            },
          }
        },
        scopedSlots: {
          customRender: 'task-' + i,
        },
      })
    }
    return [
      {
        key: 'subject',
        title: this.$t('common.subject'),
        width: 200,
        dataIndex: 'subject',
        scopedSlots: {
          customRender: 'subject',
        },
      },
      {
        key: 'courseClass',
        title: this.$t('common.subjectClass'),
        width: 200,
        dataIndex: 'courseName',
      },
      {
        key: 'finalScore',
        title: this.$t('myClass.student.finalScore'),
        align: 'center',
        width: 120,
        scopedSlots: {
          customRender: 'finalScore',
        },
      },
      ...this.semesters.map((semester, index) => {
        return {
          key: semester.gradePeriodId,
          title: semester.abbreviation + ' Percentage',
          align: 'center',
          width: 120,
          scopedSlots: { customRender: 'semester-' + semester.gradePeriodId },
        }
      }),
      ...tasks,
      {
        key: 'flex',
      },
    ]
  }

  private get excelColumns(): Array<any> {
    return [
      {
        dataIndex: 'subject',
        title: this.$t('common.subject'),
        width: 40,
      },
      {
        dataIndex: 'courseName',
        title: this.$t('common.subjectClass'),
        width: 30,
      },
      {
        dataIndex: 'finalScore',
        title: this.$t('myClass.student.finalScore'),
        width: 25,
      },
      ...this.semesters.map((semester, index) => {
        return {
          dataIndex: `s_${semester.gradePeriodId}`,
          title: semester.name + ' Percentage',
          width: 25,
        }
      }),
      ...Array.from({ length: this.maxTaskNum }).map((item, index) => [
        {
          dataIndex: `task_${index}`,
          title: this.$t('router.assignment'),
          width: 25,
        },
        {
          dataIndex: `score_${index}`,
          title: this.$t('studentDetail.score'),
          width: 20,
        },
      ]),
    ].flat()
  }
  private get locale(): any {
    return this.$store.state.locale
  }

  private getSchoolYears(): void {
    DropDownController.getSchoolYearList()
      .then(res => {
        this.schoolYears = res.data
        this.$set(this.filter, 'schoolYearId', res.data[0].key)
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getData(): void {
    // const studentId = parseInt(this.$route.params.studentId, 10)
    TaskGradeController.getStudentGradeBook(this.filter.schoolYearId, this.studentId)
      .then(res => {
        this.data = res.data.gradeBookItems
        // 成绩册 学期=>报告期
        this.semesters = res.data.gradePeriods
        let maxTaskNum = 0
        res.data.gradeBookItems.forEach(item => {
          maxTaskNum = maxTaskNum < item.taskScores.length ? item.taskScores.length : maxTaskNum
        })
        this.maxTaskNum = maxTaskNum
      })
      .catch(err => {
        console.error(err)
      })
  }

  private async exportGradeBook(): Promise<void> {
    this.exportLoading = true
    const { data } = this
    const student = (this.$parent as any)?.info
    const schoolYear = this.schoolYears.find(item => item.key === this.filter.schoolYearId)?.value
    let gradebook = cloneDeep(data).map(item => {
      let obj = {
        ...item,
        finalScore:
          item.finalScore === -1
            ? '--'
            : round(item.finalScore * 100, 1) + (item.gradeLevel ? `/${item.gradeLevel}` : ''),
      }
      Object.keys(item.gradePeriodScores || {}).forEach(key => {
        obj[`s_${key}`] =
          !item.gradePeriodScores[key].manualScore || item.gradePeriodScores[key].manualScore === -1
            ? item.gradePeriodScores[key].score === -1
              ? '--'
              : round(item.gradePeriodScores[key].score * 100, 1) +
                (item.gradePeriodScores[key].grade ? `/${item.gradePeriodScores[key].grade}` : '')
            : round(item.gradePeriodScores[key].manualScore, 1) +
              (item.gradePeriodScores[key].manualGrade
                ? `/${item.gradePeriodScores[key].manualGrade}`
                : '')
      })
      item.taskScores.forEach((element, index) => {
        obj[`task_${index}`] = element
        obj[`score_${index}`] =
          !element.score && element.score !== 0
            ? ''
            : element.score === -1
            ? '--'
            : element.score + '/' + element.topScore
      })
      return obj
    })

    const file = exportXlsx(this.excelColumns, gradebook)
    const ws = file.getWorksheet(1)

    file
      .getWorksheet(1)
      .getRow(1)
      .eachCell((cell, index) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'darkTrellis',
          fgColor: { argb: 'FFE9E9E9' },
          bgColor: { argb: 'FFE9E9E9' },
        } as any
      })
    ws.insertRow(1, [
      this.$t('common.personName'),
      this.$t('common.studentId'),
      this.$t('common.homeroom'),
      this.$t('common.advisory'),
    ])
    file
      .getWorksheet(1)
      .getRow(1)
      .eachCell((cell, index) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'darkTrellis',
          fgColor: { argb: 'FFE9E9E9' },
          bgColor: { argb: 'FFE9E9E9' },
        } as any
      })
    ws.insertRow(2, [student.name, student.studentNum, student.className, student.house])
    ws.eachRow(row => {
      row.height = 30
      row.eachCell((cell: any) => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } },
        }
        cell.style.alignment = { vertical: 'middle', horizontal: 'center' }
        if (cell.value && typeof cell.value === 'object') {
          let value = cloneDeep(cell.value)
          cell.value = value.taskName
          cell.fill = {
            type: 'pattern',
            pattern: 'darkTrellis',
            fgColor: { argb: 'FF' + value.color.replace('#', '') },
            bgColor: { argb: 'FF' + value.color.replace('#', '') },
          }
        }
      })
    })
    ws.insertRow(3, [])
    await writeFile(file, `${schoolYear}_${student.name}_Gradebook.xlsx`)
    this.exportLoading = false
  }

  private mounted(): any {
    setTimeout(() => {
      this.windowH = this.$el.parentElement?.getBoundingClientRect().height || 400
    }, 0)

    window.addEventListener('resize', this.onWindowResize)
    this.$once('hook:beforeDestory', () => {
      window.removeEventListener('resize', this.onWindowResize)
    })
  }

  @Debounce(500)
  private onWindowResize(): void {
    this.windowH = this.$el.parentElement?.getBoundingClientRect().height || 400
  }
}

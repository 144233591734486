














































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Student from './components/Student.vue'
import Parent from './components/Parent.vue'
import Curriculum from './components/Curriculum.vue'
import ClassInfo from './components/ClassInfo.vue'
// import Teacher from './components/Teacher.vue'
import Subject from './components/Subject.vue'
import TransferInfo from './components/TransferInfo.vue'
import Grading from './components/Grading.vue'
import Report from './components/Report.vue'
import Diary from './components/Diary.vue'
import Points from './components/Points.vue'
import Attendance from './components/Attendance.vue'
import KSGrading from './components/KSGrading.vue'
// import Honor from './components/Honor.vue'
// import Leadership from './components/Leadership.vue'
import Instruction from './components/Instruction.vue'
import { FeatureController, StudentController } from '@/services/request.service'
import cloneDeep from 'lodash/cloneDeep'
import { getSchoolInfo, isBase64 } from '@/utils/utils'
import { fileUploader } from '@/services/qiniu.service'
import VueCropper from 'vue-cropperjs'
import moment from 'moment'
import 'cropperjs/dist/cropper.css'
import { detailMenu } from '@/constant/constant'

Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {
    Student,
    Parent,
    Curriculum,
    ClassInfo,
    // Teacher,
    Subject,
    TransferInfo,
    Grading,
    Report,
    Points,
    Diary,
    Attendance,
    Instruction,
    KSGrading,
    // Honor,
    // Leadership,
    VueCropper,
  },
})
export default class StudentDetail extends Vue {
  private newAvatar = ''
  private avatarVisible = false
  private menuKey = ['student']
  private saveLoading = false
  private canLeave = true
  private info: any = {
    myStudent: false,
  }
  private schoolId: any = (getSchoolInfo() || {}).schoolId
  private permission = false

  private moment = moment

  private get studentId(): number {
    return parseInt(this.$route.params.studentId, 10)
  }

  private get studentIds(): any {
    return this.$store.state.studentIds
  }

  private get hasDiaryPermission(): boolean {
    return (
      this.$store.state.operationAuths.includes('2011') &&
      this.info.status === '1014' &&
      this.permission
    )
  }

  private get inAuth(): any {
    // return this.studentIds.find(item => item.key === this.studentId).value
    return this.studentIds.find(item => item.key === this.studentId)
      ? this.studentIds.find(item => item.key === this.studentId).value
      : false
  }

  private get realMenu(): any {
    const schoolMenu = Object.keys(detailMenu).includes(this.schoolId.toString())
      ? detailMenu[this.schoolId]
      : detailMenu.default
    let noAnthList =
      this.inAuth || this.info.myStudent
        ? []
        : [
            'parent',
            // 'teacher',
            'class',
            'subject',
            'transferInfo',
            'grading',
            'report',
            'points',
            // 'honor',
            // 'leadership',
            'instruction',
          ]
    noAnthList = noAnthList.concat(
      ['1016', '1018'].includes(this.info.status) ? ['subject', 'teacher'] : ['transferInfo']
    )
    return schoolMenu.filter(item => !noAnthList.find(it => it === item.key))
  }
  private get showOperation(): any {
    return this.$route.query.type !== 'message'
  }

  @Watch('studentIds', {
    immediate: true,
    deep: true,
  })
  private onStudentChange(newValue): void {
    console.log(newValue)
  }

  @Watch('studentId', { immediate: true })
  private onIdChange(val) {
    if (!val) return
    this.getStudentBrief()
    this.getPermission()
  }

  private beforeRouteLeave(to, from, next): void {
    if (!this.$store.state.forceBack) {
      if (!this.canLeave) {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            next()
          },
          onCancel: () => {
            next(false)
          },
        })
      } else {
        next()
      }
    } else {
      next()
    }
  }

  private calcBg(status, isBorder): string {
    if (status === '1016') {
      return `rgba(38, 184, 137, ${isBorder ? 1 : 0.14})`
    } else {
      return `rgba(250, 173, 20, ${isBorder ? 1 : 0.14})`
    }
  }

  private getPermission(): void {
    FeatureController.hasPermission(this.studentId, { headers: { 'X-Request-For': '2010' } })
      .then(res => {
        this.permission = res.data.value
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getStudentBrief(): void {
    StudentController.getStudentBriefInfo(this.studentId)
      .then(res => {
        this.info = res.data
        this.$store.commit('setCurriculumStudentInfo', this.info)
      })
      .catch(err => {
        console.error(err)
      })
  }

  private onStatusChange(val): void {
    if (val === 'edit') {
      this.canLeave = false
    } else if (val === 'view') {
      this.canLeave = true
    }
  }

  private changeMenu({ item, key, keyPath }): void {
    if (this.menuKey[0] !== key) {
      if (this.canLeave) {
        this.menuKey = [key]
      } else {
        this.$confirm({
          title: this.$t('common.unsaveConfirm') as string,
          onOk: () => {
            this.menuKey = [key]
            this.canLeave = true
          },
        })
      }
    }
  }

  public cropAvatar(): void {
    const cropper = this.$refs.cropper as any
    this.newAvatar = cropper?.getCroppedCanvas().toDataURL()
  }

  public saveAvatar(): void {
    this.saveLoading = true
    const avatar = this.newAvatar
    let base = avatar.split(',')
    if (isBase64(base[1])) {
      const avatarFile = this.blobToFile(
        this.dataURLtoBlob(avatar),
        'avatar_' + new Date().getTime() + '.png'
      )
      const index = avatarFile.name.lastIndexOf('.')
      const suffix = avatarFile.name.slice(index)
      const fileName = 'avatar_' + new Date().getTime() + suffix
      fileUploader(avatarFile, fileName)
        .then(res => {
          const avatarUrl = process.env.VUE_APP_FILE_URL + res.key
          StudentController.updateAvatarUrl({
            avatarUrl: avatarUrl,
            studentId: this.studentId,
          })
            .then(res => {
              this.$set(this.info, 'avatarUrl', avatarUrl)
              this.$message.success(this.$tc('common.saveSuccess'))
            })
            .catch(err => {
              console.error(err)
            })
            .finally(() => {
              this.saveLoading = false
              this.avatarVisible = false
            })
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.saveLoading = false
          this.avatarVisible = false
        })
    } else {
      this.saveLoading = false
      this.avatarVisible = false
    }
    this.$route.meta.refresh = true
  }

  public setImage(e): void {
    const file = e.target.files[0]
    if (!file.type.includes('image/')) {
      this.$message.error(this.$tc('studentDetail.wrongAvatar'))
      return
    }
    if (typeof FileReader === 'function') {
      const reader = new FileReader()

      reader.onload = event => {
        const target = event.target as any
        this.newAvatar = target.result
        const cropper = this.$refs.cropper as any
        cropper.replace(target.result)
      }

      reader.readAsDataURL(file)
    } else {
      this.$message.error(this.$tc('studentDetail.wrongBrowser'))
    }
  }

  public blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date()
    theBlob.name = fileName
    return theBlob
  }

  public dataURLtoBlob(dataurl): Blob {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  private sendMessage(): void {
    const studentId = parseInt(this.$route.params.studentId, 10)
    this.$store.commit('setMessageStudents', [studentId])
    this.$router.push({ name: 'sendMessage' })
  }

  private addDiary(): void {
    const studentId = parseInt(this.$route.params.studentId, 10)
    this.$store.commit('setDiaryStudents', [studentId])
    this.$router.push({ name: 'diaryEditor', query: { type: 'add' } }).catch(err => {})
  }

  private uploadAvatar(): void {
    this.avatarVisible = true
    this.newAvatar = cloneDeep(this.info.avatarUrl)
  }

  private preStudent(): void {
    if (this.studentIds.length <= 1) return
    const index = this.studentIds.findIndex(item => item.key === this.studentId)
    if (index === -1) {
      console.log('学号错误')
      return
    }
    if (!this.canLeave) {
      this.$confirm({
        title: this.$t('common.unsaveConfirm') as string,
        onOk: () => {
          this.$router.push({
            params: {
              studentId: this.studentIds[index - 1 === -1 ? this.studentIds.length - 1 : index - 1]
                .key,
            },
          })
          this.canLeave = true
        },
      })
    } else {
      this.$router.push({
        params: {
          studentId: this.studentIds[index - 1 === -1 ? this.studentIds.length - 1 : index - 1].key,
        },
      })
    }
  }

  private nextStudent(): void {
    if (this.studentIds.length <= 1) return
    const index = this.studentIds.findIndex(item => item.key === this.studentId)
    if (index === -1) {
      console.log('学号错误')
      return
    }
    if (!this.canLeave) {
      this.$confirm({
        title: this.$t('common.unsaveConfirm') as string,
        onOk: () => {
          this.$router.push({
            params: {
              studentId: this.studentIds[index + 1 === this.studentIds.length ? 0 : index + 1].key,
            },
          })
          this.canLeave = true
        },
      })
    } else {
      this.$router.push({
        params: {
          studentId: this.studentIds[index + 1 === this.studentIds.length ? 0 : index + 1].key,
        },
      })
    }
  }
}

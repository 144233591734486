var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"instruction-viewer"},[_c('div',{staticClass:"section-title"},[_c('div',{staticClass:"flex-style align-center"},[_c('div',{staticClass:"title-bar"}),_c('span',{staticClass:"title-text"},[_vm._v(_vm._s(_vm.$t('myClass.student.instruction')))])]),(!_vm.edit && _vm.status !== '1016')?_c('a-button',{staticClass:"edit-button",attrs:{"type":"link","icon":"edit"},on:{"click":function($event){return _vm.changeEdit(true)}}},[_vm._v(_vm._s(_vm.$t('common.edit')))]):_vm._e()],1),(!_vm.edit)?_c('div',{staticClass:"section-content"},[_c('div',{staticClass:"form-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('myClass.student.medical')))]),_c('pre',{staticClass:"content"},[_vm._v(_vm._s(_vm.data.medical || '-'))])]),_c('div',{staticClass:"form-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('myClass.student.diet')))]),_c('pre',{staticClass:"content"},[_vm._v(_vm._s(_vm.data.diet || '-'))])]),_c('div',{staticClass:"form-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('myClass.student.national')))]),_c('pre',{staticClass:"content"},[_vm._v(_vm._s(_vm.data.national || '-'))])]),_c('div',{staticClass:"form-item"},[_c('span',[_vm._v(_vm._s(_vm.$t('myClass.student.media')))]),_c('pre',{staticClass:"content"},[_vm._v(_vm._s(_vm.data.medium || '-'))])])]):_c('a-form',{staticClass:"form",attrs:{"form":_vm.instructionForm,"layout":"horizontal","label-col":_vm.formItemLayout.labelCol,"wrapper-col":_vm.formItemLayout.wrapperCol}},[_c('a-form-item',{attrs:{"label":_vm.$t('myClass.student.medical')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'medical',
          {
            initialValue: _vm.data.medical,
          } ]),expression:"[\n          'medical',\n          {\n            initialValue: data.medical,\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"autoSize":_vm.textareaSize,"placeholder":_vm.$t('common.enterCont')}}),_c('div',{staticClass:"flex-style align-center"},[_c('div',{staticClass:"label-container"},[_c('i',{staticClass:"medical-icon"}),_c('span',[_vm._v(_vm._s(_vm.$t('myClass.student.showLabel'))+"：")])]),_c('a-radio-group',{model:{value:(_vm.medicalLabelVis),callback:function ($$v) {_vm.medicalLabelVis=$$v},expression:"medicalLabelVis"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v(_vm._s(_vm.$t('common.true')))]),_c('a-radio',{attrs:{"value":false}},[_vm._v(_vm._s(_vm.$t('common.false')))])],1)],1)],1),_c('a-form-item',{attrs:{"label":_vm.$t('myClass.student.diet')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'diet',
          {
            initialValue: _vm.data.diet,
          } ]),expression:"[\n          'diet',\n          {\n            initialValue: data.diet,\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"autoSize":_vm.textareaSize,"placeholder":_vm.$t('common.enterCont')}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('myClass.student.national')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'national',
          {
            initialValue: _vm.data.national,
          } ]),expression:"[\n          'national',\n          {\n            initialValue: data.national,\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"autoSize":_vm.textareaSize,"placeholder":_vm.$t('common.enterCont')}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('myClass.student.media')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'medium',
          {
            initialValue: _vm.data.medium,
          } ]),expression:"[\n          'medium',\n          {\n            initialValue: data.medium,\n          },\n        ]"}],staticStyle:{"width":"100%"},attrs:{"autoSize":_vm.textareaSize,"placeholder":_vm.$t('common.enterCont')}}),_c('div',{staticClass:"flex-style align-center"},[_c('div',{staticClass:"label-container"},[_c('i',{staticClass:"media-icon"}),_c('span',[_vm._v(_vm._s(_vm.$t('myClass.student.showLabel'))+"：")])]),_c('a-radio-group',{model:{value:(_vm.mediaLabelVis),callback:function ($$v) {_vm.mediaLabelVis=$$v},expression:"mediaLabelVis"}},[_c('a-radio',{attrs:{"value":true}},[_vm._v(_vm._s(_vm.$t('common.true')))]),_c('a-radio',{attrs:{"value":false}},[_vm._v(_vm._s(_vm.$t('common.false')))])],1)],1)],1)],1),(_vm.edit)?_c('div',{staticClass:"save-container"},[_c('div',{staticClass:"flex",staticStyle:{"width":"100%","justify-content":"flex-end","padding":"0 16px"}},[_c('a-button',{attrs:{"size":"large"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('common.cancel')))]),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary","size":"large","saveLoading":_vm.saveLoading},on:{"click":_vm.saveInstruction}},[_vm._v(_vm._s(_vm.$t('common.save')))])],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
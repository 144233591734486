






















































import { Component, Vue } from 'vue-property-decorator'
import { StudentController, DropDownController } from '@/services/request.service'

@Component
export default class classInfo extends Vue {
  private data: any = []
  private loading: boolean = false

  private filter: any = {
    schoolYearId: undefined,
  }
  private schoolYearList: any = []

  private get locale(): any {
    return this.$store.state.locale
  }

  private get columns(): Array<any> {
    return [
      {
        dataIndex: 'classType',
        key: 'classType',
        title: this.$t('myClass.student.classType'),
        customRender: text => {
          return this.$t(`myClass.student.classTypeEnum.${text}`)
        },
      },
      {
        dataIndex: 'classTypeName',
        key: 'classTypeName',
        title: this.$t('myClass.student.className'),
      },
      {
        dataIndex: 'teachers',
        key: 'teachers',
        title: this.$t('myClass.student.teacher'),
        scopedSlots: { customRender: 'teachers' },
      },
    ]
  }

  private created(): void {
    this.getSchoolYearList()
  }

  private getSchoolYearList(): void {
    DropDownController.getSchoolYearRuleList().then(res => {
      this.schoolYearList = res.data.filter(item => item.extraValue !== '1100')
      this.filter.schoolYearId = this.schoolYearList[0].key
      this.getData()
    })
  }

  private getData(): void {
    this.loading = true
    const studentId = parseInt(this.$route.params.studentId, 10)
    StudentController.classInfo(this.filter.schoolYearId, studentId)
      .then(res => {
        this.data = res.data
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => (this.loading = false))
  }
}

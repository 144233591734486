










































import { Emit, Component, Vue, Prop, PropSync } from 'vue-property-decorator'
// import DiaryEditor from './components/DiaryEditor.vue'
Component.registerHooks(['beforeRouteLeave'])

@Component({
  components: {},
})
export default class InjuredMark extends Vue {
  @Prop({ default: () => [] }) private tagInfo!: Array<any>
  @Prop({ default: 0 }) private readonly width!: Number
  @Prop({ default: 0 }) private readonly height!: Number
  @Prop({ default: '' }) private readonly type!: String
  @PropSync('visible', { default: [] }) private visibleArray: any

  private descriptionTemp: Array<string> = []

  private created(): void {
    this.$nextTick(() => {
      this.initialData()
    })
  }

  private initialData(): void {
    this.descriptionTemp = this.tagInfo.map(item => item.description)
  }

  private del(index): void {
    this.descriptionTemp.splice(index, 1)
    this.tagInfo.splice(index, 1)
    this.$set(this.visibleArray, index, false)
  }

  private cancel(index): void {
    this.descriptionTemp[index] = this.tagInfo[index].description
    this.$set(this.visibleArray, index, false)
  }

  @Emit('confirm')
  private confirm(index): Array<any> {
    this.tagInfo[index].description = this.descriptionTemp[index]
    this.$set(this.visibleArray, index, false)
    return this.tagInfo
  }
}

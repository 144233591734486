



































































































































import { Component, Vue, Emit, Prop } from 'vue-property-decorator'
import { StudentController } from '@/services/request.service'

@Component
export default class Instruction extends Vue {
  @Prop({ default: '1014' }) private readonly status!: string

  private instructionForm: any
  private data: any = {}
  private edit: boolean = false
  private formItemLayout: any = {
    labelCol: { span: 7 },
    wrapperCol: { span: 10 },
  }
  private saveLoading: boolean = false
  private textareaSize: any = {
    minRows: 3,
    maxRows: 8,
  }
  private medicalLabelVis: boolean = false
  private mediaLabelVis: boolean = false

  private beforeCreate(): void {
    this.instructionForm = this.$form.createForm(this)
  }

  private created(): void {
    this.getData()
  }

  private changeEdit(status): void {
    this.edit = status
    this.changeStatus(status ? 'edit' : 'view')
  }

  private cancel(): void {
    this.edit = false
    this.medicalLabelVis = this.data.medicalTag
    this.mediaLabelVis = this.data.mediumTag
  }

  private getData(): void {
    const studentId = parseInt(this.$route.params.studentId, 10)
    StudentController.getRemarks(studentId)
      .then(res => {
        this.$nextTick(() => {
          this.data = res.data
          this.medicalLabelVis = res.data.medicalTag
          this.mediaLabelVis = res.data.mediumTag
        })
      })
      .catch(err => {
        console.error(err)
      })
  }

  private saveInstruction(): void {
    this.saveLoading = true
    const form = this.instructionForm
    form.validateFields((err: any, values: any) => {
      if (err) {
        this.saveLoading = false
        return
      } else {
        const request = {
          studentId: parseInt(this.$route.params.studentId, 10),
          remarks: {
            ...values,
            mediumTag: this.mediaLabelVis,
            medicalTag: this.medicalLabelVis,
          },
        }
        StudentController.updateStudentRemark(request)
          .then(res => {
            this.$message.success(this.$tc('common.saveSuccess'))
            this.changeEdit(false)
            this.getData()
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.saveLoading = false
          })
      }
    })
  }

  @Emit('change')
  private changeStatus(mode): void {
    return mode
  }
}
